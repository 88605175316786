import React from 'react';
import MainSplash from './sections/MainSplash';
import About from './sections/About';
import './App.scss';
import 'animate.css/animate.min.css';
import HardSkills from './sections/HardSkills';
import SoftSkills from './sections/SoftSkills';
import Education from './sections/Education';
import Portfolio from './sections/Portfolio';
import Footer from './sections/Footer';


function App() {
  return (
    <div className="App">
      <MainSplash />
      <About />
      <SoftSkills />
      <HardSkills />
      <Education />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
