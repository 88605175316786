import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PortfolioCard from "../components/PortfolioCard";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitles from "../components/SectionTitles";
import UNC from "../images/catering.jpg";
import SSWedding from "../images/SWedding.jpg";
import LostValley from "../images/LostValley.jpg";
// import Webbs from '../images/webbs.jpg';
import Countryside from "../images/countryside.jpg";
import Steph from "../images/steph.jpg";
import IMT from "../images/IMT.jpg";

const Portfolio = () => (
  <section id="Portfolio">
    <Container>
      <Row>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <SectionTitles titleNames={"Portfolio"} />
        </ScrollAnimation>
      </Row>
      <Row>
        <Col>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <p>
              I have successfully completed over 15 corporate software projects,
              which included part traceablity, job tracking, high volume
              labeling, and embedded device integration. What I can show you are
              some of the public projects I have completed while freelancing.
            </p>
          </ScrollAnimation>
        </Col>
      </Row>
      <Row>
        <Col>
          <PortfolioCard
            url="https://imaginemoretechnologies.com"
            cardTitle="Imagine More Technologies"
            cardBody="I started creating sites for customers with 
                    my startup Rescue Dog Design in 2012.  I decided to rebrand the 
                    business to Imagine More Technologies as the rebranding 
                    better positions the business in the market."
            animation="bounceInUp"
            image={IMT}
          />
        </Col>
        <Col>
          <PortfolioCard
            url="https://upnorthcatering.com"
            cardTitle="Up North Catering"
            cardBody="Up North Catering L.L.C. is an established
                        small business in Northern Michigan. Once
                        the site went live business inquiries
                        increased 40%."
            animation="bounceInUp"
            image={UNC}
          />
        </Col>
        <Col>
          <PortfolioCard
            url="https://happilyeverslosser.imaginemoretechnologies.com/"
            cardTitle="Wonderful Wedding"
            cardBody="This site was customized to show the uniqueness
                        of the couple. You will find a dynamic firework
                        display on the splash page. The site details
                        the wedding party, accommodations, and attractions
                        for the area. You will also see at the bottom of
                        the page an RSVP system for those who will be
                        attending."
            animation="bounceInUp"
            image={SSWedding}
          />
        </Col>

        <Col>
          <PortfolioCard
            url="https://lostvalleycampground.com"
            cardTitle="Lost Valley Campground"
            cardBody="This site was custom built from the ground up.
                        It features mobile first development (optimized
                        for tablets / phones), single page dynamic
                        scrolling and an interactive calendar that Lost
                        Valley can edit. This site gives Lost Valley the
                        online visibility they required and allows them
                        to address campers from a central location."
            animation="bounceInUp"
            image={LostValley}
          />
        </Col>

        <Col>
          <PortfolioCard
            url="https://countrysideappliancellc.com/"
            cardTitle="Countryside Appliance"
            cardBody="Countryside Appliance is a wonderful small business
                        located in West Branch, Michigan. They provide
                        excellent service and high quality appliance
                        repair to Northeast Michigan. We were excited to
                        be able to help consolidate and grow their digital
                        marketing footprint with a functional, mobile
                        supported website."
            animation="bounceInUp"
            image={Countryside}
          />
        </Col>
        <Col>
          <PortfolioCard
            url="https://stephanienicolewindsor.com"
            cardTitle="Custom Tailored"
            cardBody="This site was built to to provide an online
                        portfolio. This site contains a full resume,
                        photo gallery for certificates and contact
                        information. The resume is formated
                        as a .pdf allowing interested parties to
                        download and print."
            animation="bounceInUp"
            image={Steph}
          />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Portfolio;
