import React from "react";

const MainSplash = () => (
  <div className="Header-Background">
    <header className="App-Header">
      <div className="Header-Box">
        Hello, I'm <span>Sam Slosser</span>.<br />
        Software Engineer
      </div>
    </header>
  </div>
);

export default MainSplash;
