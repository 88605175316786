import React from 'react';

const TechButtons = ({ blueButtons, skillName }) => {

    const items = [];

    for (let i = 0; i < 5; i++) {
        if (i < blueButtons) {
            items.push(<div key={i} className="Blue-Circles"></div>)
        } else { items.push(<div key={i} className="White-Circles"></div>) }
    }
    items.push(<span key={skillName} className="Skill-Label">{skillName}</span>)
    return (
        
           <div className="Skill-Component"> {items}</div>
        
    )
}

export default TechButtons