import React from "react";
import { Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const PortfolioCard = ({ url, cardTitle, cardBody, animation, image }) => (
  <ScrollAnimation
    className="portfolioAnimatedDiv"
    animateIn={animation}
    animateOnce={true}
  >
    <Card className={"portfolioCard"}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        <Card.Img variant="top" src={image} alt={cardTitle} />
        <Card.Body>
          <Card.Title>{cardTitle}</Card.Title>
          <Card.Text>{cardBody}</Card.Text>
        </Card.Body>
      </a>
    </Card>
  </ScrollAnimation>
);

export default PortfolioCard;
