import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitles from '../components/SectionTitles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCircle, faSearch, faChalkboardTeacher, faRecycle, faAward } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faComments, faFileAlt } from '@fortawesome/free-regular-svg-icons';

const SoftSkills = () => (
    <section className="Off-Section" id="SoftSkills">
        <Container>
            <Row>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <SectionTitles titleNames={"Soft Skills"} />
                </ScrollAnimation>
            </Row>
            <Row>
                <Col>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <p>Soft skills are universal and not associated with a particular
                        job or industry.  Think communication, active listening, or empathy.
                        Soft skills indicate how you’ll perform your duties.</p>
                    </ScrollAnimation>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>
                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title >
                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faComments} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Communication</h3>
                                <Card.Text>
                                    Soft skills wizard and a reporting ninja.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>

                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>
                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faBrain} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Leadership</h3>
                                <Card.Text>
                                    Leads by example and promotes teamwork.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>

                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>
                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faCalendarAlt} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Planning</h3>
                                <Card.Text>
                                    Skilled project planner and efficiency expert.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>

                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faSearch} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Analysis</h3>
                                <Card.Text>
                                    Finds the root cause and provides quality solutions.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>
                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faFileAlt} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Documentation</h3>
                                <Card.Text>
                                    Always creates and references documentation.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>

                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>
                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faChalkboardTeacher} inverse transform="shrink-8" />
                                    </span>
                                </Card.Title>
                                <h3>Instruction</h3>
                                <Card.Text>
                                    Skilled at instructing and receiveing instructions.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>
                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faAward} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Achiever</h3>
                                <Card.Text>
                                    Always strive to beat expectations.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
                <Col md={12} lg={3}>
                    <ScrollAnimation animateIn="flipInX" animateOnce={true}>

                        <Card className="Soft-Skills-Card">
                            <Card.Body className="text-center">
                                <Card.Title>

                                    <span className="fa-layers fa-fw Skills-Icon">
                                        <FontAwesomeIcon icon={faCircle} />
                                        <FontAwesomeIcon icon={faRecycle} inverse transform="shrink-6" />
                                    </span>
                                </Card.Title>
                                <h3>Adaptability</h3>
                                <Card.Text>
                                    I can adapt quickly and seamlessly.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
            </Row>
        </Container>
    </section>
)


export default SoftSkills