import React from "react";
import SectionTitles from "../components/SectionTitles";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Veteran from "../images/vet-opt.png";

const About = () => (
  <section id="AboutMe">
    <Container>
      <Row>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <SectionTitles titleNames={"About Me"} />
        </ScrollAnimation>
      </Row>
      <Row>
        <Col xs={12} md={10}>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <p>
              I’m a software engineer who focuses on front end development. I
              have a talent for generating ideas, solving problems, and managing
              complex projects. I have adopted useful skills from my work
              experiences and I have collected several certifications along the
              way. I approach every day with an open outlook and never shy away
              from a challenge. I enjoy team dynamics and guiding others to
              achieve their goals. I believe in continuous education as
              experience and knowledge produce quality leaders. My goal is to
              stay challenged and to provide a valuable service to my employer.{" "}
            </p>
          </ScrollAnimation>
        </Col>
        <Col md={2}>
          <div className="text-center">
            <ScrollAnimation
              animateIn="pulse"
              duration={5}
              initiallyVisible={true}
            >
              <img src={Veteran} alt="veteran" />
            </ScrollAnimation>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default About;
