import React from "react";
import SectionTitles from "../components/SectionTitles";
import TechButtons from "../components/TechButtons";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const HardSkills = () => (
  <section id="HardSkills">
    <Container>
      <Row>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <SectionTitles titleNames={"Hard Skills"} />
        </ScrollAnimation>
      </Row>
      <Row>
        <Col>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <p>
              Hard skills are abilities we learn on the job, through formal
              education, or additional training. Hard skills show what you can
              do in your job.
            </p>
          </ScrollAnimation>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"Git"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"JavaScript"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Security"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Docker"} />
          </ScrollAnimation>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"AWS"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Linux"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"Next.js"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Material UI"} />
          </ScrollAnimation>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"React"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Apache"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"HTML/CSS"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"Figma"} />
          </ScrollAnimation>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"Laravel"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={3} skillName={"SQL"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={5} skillName={"UI/UX"} />
          </ScrollAnimation>
        </Col>
        <Col sm={12} md={3}>
          <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
            <TechButtons blueButtons={4} skillName={"IIS"} />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  </section>
);

export default HardSkills;
