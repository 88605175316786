import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitles from '../components/SectionTitles';
import APSU_Image from '../images/0.png';
import PSTCC_Image from '../images/1.png';
import EducationItems from '../components/EducationItems';
import ScrollAnimation from 'react-animate-on-scroll';

const Education = () => (
    <section id="Education" className="Off-Section">
        <Container>
            <Row>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <SectionTitles titleNames={"Education"} />
                </ScrollAnimation>
            </Row>
            <Row>
                <Col>
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <p>I have excelled as a non-traditonal student working 
                            full-time in the field of engineering and attending 
                            classes part-time.  
                </p>
                    </ScrollAnimation>
                </Col>


            </Row>
            <Row>
                <Col>
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                        <EducationItems
                            image={APSU_Image}
                            schoolName="Austin Peay State University"
                            degree="Bachelor of Science - BS, Computer Science"
                            date="2016 - 2019"
                            major="Major - Information Systems"
                            minor="Minor - Internet and Web Technology"
                        />
                    </ScrollAnimation>
                </Col>

            </Row>
            <hr />
            <Row>
                <Col>
                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                        <EducationItems
                            image={PSTCC_Image}
                            schoolName="Pellissippi State Community College"
                            degree="Associate of Science (A.S.), Computer Science, Cum Laude"
                            date="2012 - 2015"

                        />
                    </ScrollAnimation>
                </Col>

            </Row>
            <hr />
            <Row>
                <Col>
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <EducationItems
                        image={PSTCC_Image}
                        schoolName="Pellissippi State Community College"
                        degree="Associate's Degree, General Technology - Interdisciplinary, Cum Laude"
                        date="2012 - 2014"

                    />
                    </ScrollAnimation>
                </Col>

            </Row>
        </Container>
    </section >
)

export default Education