import React from 'react';

const EducationItems = ({ image, schoolName, degree, date, major, minor }) => {
    const majorMinor = () => {
        // check if major is pass to show the section
        if (major) {
            return (<p>
                        {major}<br />
                        {minor}
                    </p>)
        }

    }
    return (
        <div className="Education-Component">
            <div className="Education-Icon">
                <img src={image} alt={schoolName} height="50" width="50" />
            </div>
            <div className="Education-Description ">
                <p>
                    <span className="lead">{schoolName}</span> <br />
                    {degree}<br />
                    {date}
                </p>
                {majorMinor()}

            </div>
            <div className="clearfix"></div>

        </div>


    );
}

export default EducationItems